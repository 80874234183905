import moment, {MomentInput} from "moment";
import {history} from "@@/core/history";

import {DivisionType, SelectOptionValue} from "@/types/common/fields";
import {DistanceDto} from "@/types/common/tci-run-dto";

export const getDivisionNameBy = (inputValue: number, listData: Array<DivisionType>): string => {
  const ele = listData.find((e: DivisionType) => e.value === inputValue);
  return ele?.name || "";
};

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (const c of ca) {
    const tmp = c.trim();
    if (tmp.startsWith(name)) {
      return tmp.substring(name.length, c.length);
    }
  }
  return "";
};

export const showPhoneNumber = (field?: string, showView?: boolean): string | undefined => (
  // Show last three digits of phone number
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  showView ? field : `*******${field?.substring(field.length - 3) ?? ""}`
);

/* eslint-disable */
export const removeVietnameseTones = (str: string): string => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
};
/* eslint-enable */

export const getNameFromFullName = (fullName: string): string => {
  if (fullName) {
    const fullNameArr = fullName.trim().split(" ");
    return fullNameArr[fullNameArr.length - 1];
  }
  return "";
};

export const filterOption = (input: string, option?: SelectOptionValue) : boolean => (
  option?.children
    ? removeVietnameseTones(option.children.toLowerCase())
      .includes(removeVietnameseTones(input.toLowerCase()))
    : false
);

export const formatterNumber = (val: 0 | undefined) => {
  if (!val) return 0;
  return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ".");
};

export const parserNumber = (val?: string) => {
  if (!val) return 0;
  return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(2);
};

export const checkNullString = (value?: string): string => {
  if (value?.trim()) {
    return `${value}-`;
  }
  return "";
};

export const containsDuplicates = (array: any) => {
  return array.length !== new Set(array).size;
};

/** use this to set search params without reloading page */
export const setUrlQuery = (key: string, value: string): void => {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.pushState(null, "", url.toString());
};

export const getUrlQuery = (key: string, getAll = false): string | Array<string> => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return getAll ? urlSearchParams.getAll(key) : (urlSearchParams.get(key) || "");
};

export const htmltoText = (html: string): string => {
  let text = html;
  text = text.replace(/\n/giu, "");
  text = text.replace(/<style([\s\S]*?)<\/style>/giu, "");
  text = text.replace(/<script([\s\S]*?)<\/script>/giu, "");
  text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
  text = text.replace(/<\/div>/giu, "\n\n");
  text = text.replace(/<\/li>/giu, "\n");
  text = text.replace(/<li.*?>/giu, "  *  ");
  text = text.replace(/<\/ul>/giu, "\n\n");
  text = text.replace(/<\/p>/giu, "\n\n");
  text = text.replace(/<br\s*[/]?>/giu, "\n");
  text = text.replace(/<[^>]+>/giu, "");
  text = text.replace(/^\s*/gimu, "");
  text = text.replace(/ ,/giu, ",");
  text = text.replace(/ +/giu, " ");
  text = text.replace(/\n+/giu, "\n\n");
  text = text.replace(/&nbsp;/giu, " ");
  return text;
};

export const transformTimeField = (time: MomentInput): moment.Moment | undefined => {
  return time ? moment(time) : undefined;
};

export const goToLogin = (callbackUrl?: string): void => {
  history.push({
    pathname: "/login",
    search: callbackUrl
      ? `?activeTabKey=login&redirect=${encodeURIComponent(callbackUrl)}`
      : undefined,
  });
};

export const searchResult = (container: string, input: string): boolean => {
  return removeVietnameseTones(container)
    .toLowerCase()
    .includes(removeVietnameseTones(input.toLowerCase()));
};

export const renderOnlineDistance = (e: DistanceDto) => {
  if (!e.maximumDistance) {
    return `> ${e.minimumDistance}`;
  }
  if (!e.minimumDistance) {
    return `< ${e.maximumDistance}`;
  }
  return `${e.minimumDistance} - ${e.maximumDistance}`;
};
