import { extend } from 'umi-request';
import { notification } from 'antd';

const requestPublicApi = extend({
  errorHandler: error => {
    const { response, data } = error;
    notification.error({
      message: `Yêu cầu lỗi ${data?.status}`,
      description: data?.error,
    });
    return response;
  }
});

export default requestPublicApi;