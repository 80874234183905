import {Effect, Reducer} from "umi";

import {ActionModelInterface} from "@/types/common/interfaces";

export interface EventDetailState {
  eventId: number | undefined;
  category: number | undefined;
  eventOnOffId: number | undefined;
}

interface EventDetailInterface {
  namespace: string,
  state: EventDetailState;
  effects: {
    detail: Effect;
  };
  reducers: {
    setDetail: Reducer<EventDetailInterface, ActionModelInterface<EventDetailState>>;
  };
}

const initialState: EventDetailState = {
  eventId: undefined,
  category: undefined,
  eventOnOffId: undefined,
};

const EventDetail: EventDetailInterface = {
  namespace: "eventDetail",
  state: initialState,
  effects: {
    * detail({payload}, {put}) {
      yield put({
        type: "setDetail",
        payload,
      });
    },
  },
  reducers: {
    setDetail(state, {payload}) {
      return {...state, ...payload};
    },
  },
};
export default EventDetail;
