import {history} from "@@/core/history";
import {Col, Image, Row} from "antd";
import React, {useEffect, useState} from "react";

import {HTTP_OK} from "@/constants/common";
import BlogApi from "@/services/common/blog";
import {BlogDto} from "@/types/common/tci-run-dto";
import {htmltoText, removeVietnameseTones} from "@/utils/common";

import "./style.less";
import {BASE_URL_CDN} from "@/constants/baseUrl";

const RunningKnowledge: React.FC = () => {
  const [blogs, setBlogs] = useState<Array<BlogDto>>();

  const getBlogFooter = () => {
    BlogApi.getBlogFooter().then(response => {
      if (response.statusCode === HTTP_OK) {
        setBlogs(response.data);
      }
    });
  };

  const seeDetail = (blogId: number, blogName: string) => {
    history.push(`/blog/detail-blog/${removeVietnameseTones(blogName).trim()
      .replace(/  +/g, " ")
      .replaceAll(" ", "-")}-${blogId}.html`);
  };

  useEffect(() => {
    getBlogFooter();
  }, []);

  if (window.location.pathname === "/login") {
    return null;
  }

  return (
    <div className="footer">
      {
        window.location.pathname === "/manage-page" ? null
          : (
            <div className="knowledge">
              <div className="heading">Tin tức</div>
              <div className="line" />
              <Row gutter={[16, 32]} className="listNews w-full">
                {
                  blogs?.map(blog => (
                    <Col xs={24} xl={12} key={blog.id}>
                      <Row gutter={[16, 16]} className="w-full">
                        <Col
                          xs={10}
                          lg={9}
                          className="blogImage"
                          style={{backgroundImage: `url(${BASE_URL_CDN + blog.urlBanner})`}}
                        />
                        <Col xs={14} lg={15} className="postTitle">
                          <h1>{blog.blogName}</h1>
                          <div className="blogContent">
                            {htmltoText(blog.content)}
                          </div>
                          <a onClick={() => seeDetail(blog.id, blog.blogName)}>Xem thêm</a>
                        </Col>
                      </Row>
                    </Col>
                  ))
                }
              </Row>
            </div>
          )
      }
      <div className="footerContent w-full">
        <Row gutter={[8, 0]} align="top" className="w-full">
          <Col xs={24} xl={4} className="logoFooter">
            <Image height={40} src={`${BASE_URL_CDN}background/tci_run_logo.svg`} preview={false} />
            <div className="mt-12">
              <Image height={35} src={`${BASE_URL_CDN}background/api_logo_pwrdBy_strava_horiz_light.svg`} preview={false} />
            </div>
          </Col>
          <Col xs={{span: 24, order: 3}} xl={{span: 10, order: 2}} className="detail listLinkFooter">
            <ul>
              <li><a href="/">Trang chủ</a></li>
              <li><a href="/blog">Tin tức</a></li>
              <li><a href="/events">Tất cả sự kiện</a></li>
            </ul>
          </Col>
          <Col xs={{span: 24, order: 2}} xl={{span: 10, order: 3}} className="detail listContactFooter">
            <ul>
              <li>GIẢI CHẠY TCI RUN FOR HEALTH</li>
              <li>Đơn vị tổ chức/ Organizer: TCI</li>
              <li>Website: <a href="https://run.tcihospital.vn/">run.tcihospital.vn</a></li>
              <li>Hotline: <a href="tel:0936058950">0936058950</a></li>
              <li>Email: <a href="mail:tcirunforhealth@gmail.com">tcirunforhealth@gmail.com</a></li>
            </ul>
          </Col>
        </Row>
        <Row gutter={[8, 0]}>
          <Col xs={24} className="listSocialFooter">
            <div className="itemSocialFooter">
              <a href="https://www.youtube.com/@TCIRunForHealth" target="_blank" rel="noreferrer">
                <img src={`${BASE_URL_CDN}background/youtube.svg`} alt="youtube-TCI" />
              </a>
            </div>
            <div className="itemSocialFooter">
              <a href="https://www.facebook.com/tci.runforhealth" target="_blank" rel="noreferrer">
                <img src={`${BASE_URL_CDN}background/fanpage.svg`} alt="fanpage-TCI" />
              </a>
            </div>
            <div className="itemSocialFooter">
              <a href="https://www.facebook.com/groups/tcirunforhealth" target="_blank" rel="noreferrer">
                <img src={`${BASE_URL_CDN}background/groups.svg`} alt="group-TCI" />
              </a>
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 0]} className="copyright">
          <Col xs={24}>
            Copyright © 2023
          </Col>
        </Row>
      </div>
    </div>

  );
};
export default RunningKnowledge;
