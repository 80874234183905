import {useState} from "react";

export interface NavigatorHandlerState {
  reloadMenu: boolean;
  setReloadMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export default () : NavigatorHandlerState => {
  const [reloadMenu, setReloadMenu] = useState<boolean>(false);

  return {reloadMenu, setReloadMenu};
};
