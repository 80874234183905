export const AUTH_PATHS: Array<string> = ["/user/update", "/create-blog", "/create-events", "/edit-events", "/manage-page", "/events/offline-register", "/edit-blog", "/cancel-event"]; // những màn cần login thì mới đc vào

// TEST
export const BASE_URL_CDN = "https://cdn.thucuc.vn/tci-run-uat/";
export const BASE_64_URL_UPLOAD = "https://uat-tcirun.benhvientci.org";

// PRODUCT
// export const BASE_URL_CDN = "https://cdn.thucuc.vn/production-tci-run/";
// export const BASE_64_URL_UPLOAD = "https://run.tcihospital.vn";

export const BASE_URL_AUTH = `${BASE_URL_RUN}/auth`;
export const BASE_URL_USER = `${BASE_URL_RUN}/user`;
export const BASE_URL_EVENT = `${BASE_URL_RUN}/event`;
export const BASE_URL_UPLOAD = `${BASE_URL_RUN}/upload`;
export const BASE_URL_BANNER = `${BASE_URL_RUN}/banner`;
export const BASE_URL_REGISTER = `${BASE_URL_RUN}/register`;
export const BASE_URL_BLOG = `${BASE_URL_RUN}/blog`;
export const BASE_URL_ADMIN = `${BASE_URL_RUN}/admin`;
export const BASE_URL_TEAM = `${BASE_URL_RUN}/team`;
export const BASE_URL_MASTER = `${BASE_URL_RUN}/mst-strava`;
