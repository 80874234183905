/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {Effect, Reducer} from "umi";

import {ActionModelInterface} from "@/types/common/interfaces";

export interface SampleState {
  message: string | undefined;
}

interface SampleModelInterface {
  namespace: string,
  state: SampleState;
  effects: {
    detail: Effect;
    reset: Effect;
  };
  reducers: {
    updateData: Reducer<SampleModelInterface, ActionModelInterface<SampleState>>;
    resetData: Reducer<SampleModelInterface, ActionModelInterface<undefined>>;
  };
}

const initState: SampleState = {message: undefined};

const sample: SampleModelInterface = {
  namespace: "sample",
  state: initState,
  effects: {
    * detail({payload}, {put}) {
      yield put({
        type: "updateData",
        payload,
      });
    },
    * reset({payload}, {put}) {
      yield put({
        type: "resetData",
        payload,
      });
    },
  },
  reducers: {
    updateData(state, {payload}) {
      return {...state, ...payload};
    },
    resetData(state) {
      return {...state, ...initState};
    },
  },
};

export default sample;
