/**
 * request
 * api : https://github.com/umijs/umi-request
 */
import {notification} from "antd";
import {history} from "umi";
import type {ResponseError} from "umi-request";
import {extend} from "umi-request";

import {AUTH_PATHS} from "@/constants/baseUrl";
import {ENDS_WITH_SLASHES_PATTERN} from "@/constants/patterns";

enum HttpError {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_ACCEPTABLE = 406,
}

const codeMessage: {
  [key: number]: string
} = {
  200: "Thành công",
  201: "Thêm mới hoặc cập nhập thành công",
  204: "Xóa thành công",
  400: "Bad Request",
  401: "Hết thời gian đăng nhập hoặc chưa đăng nhập",
  403: "Hết thời gian đăng nhập hoặc chưa đăng nhập",
  404: "Not Found",
  406: "Chưa chọn phòng làm việc",
  410: "Tài nguyên được yêu cầu sẽ bị xóa vĩnh viễn và sẽ không còn nữa.",
  422: "Unprocessable Entity",
  500: "Lỗi nghiệp vụ, vui lòng liên hệ với IT",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Time-out",
};

/**
 * exception handler
 */

interface HasMessage {
  message?: string;
}

const defaultErrorMessage: HasMessage = {message: "Lỗi không xác định"};

const errorHandler = <T extends HasMessage>(error: ResponseError<T>): T => {
  const {response, data} = error;
  if (response.status) {
    const {status} = response;
    const errorText = codeMessage[status] || response.statusText;
    if (response.status === HttpError.UNAUTHORIZED || response.status === HttpError.FORBIDDEN) {
      localStorage.removeItem("token");
      const url = new URL(window.location.href);
      const isEndsWithSlashes = ENDS_WITH_SLASHES_PATTERN.test(url.pathname);
      // remove slashes if possible
      const pathname = isEndsWithSlashes
        ? url.pathname.replace(ENDS_WITH_SLASHES_PATTERN, "")
        : url.pathname;
      if (AUTH_PATHS.includes(pathname)) {
        // history.push("/user");
        history.push("/login?activeTabKey=login");
      }
      const responseData: HasMessage = {};
      return responseData as T;
    }
    notification.error({
      message: `Yêu cầu lỗi ${status}`,
      description: errorText,
    });
  } else if (!response) {
    notification.warning({
      description: "Hết phiên làm việc, hãy đăng nhập lại hệ thống.",
      message: "Hết phiên hệ thống",
      duration: 0,
    });
    return defaultErrorMessage as T;
  }

  return data ?? defaultErrorMessage;
};

const requestApi = extend({
  errorHandler,
  headers: {Authorization: `Bearer ${localStorage.getItem("token") as string}`},
  // credentials: "include", // Yêu cầu mặc định có mang theo cookie không
});

export default requestApi;
