import {useState} from "react";

export interface CompanyState {
  companyId: number | undefined;
  setCompanyId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export default () : CompanyState => {
  const [companyId, setCompanyId] = useState<number | undefined>();

  return {companyId, setCompanyId};
};
