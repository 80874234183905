import type {Layout} from "@/types/common/interfaces";

export const HTTP_OK = 200;

export const DATE_TIME_FORMAT = "DD/MM/YYYY (HH:mm)";
export const FORMAT_HOUR = "HH:mm";
export const CLIENT_ID = 113387;
export const FormItemDefaultProps: Layout = {
  labelCol: {xs: 24},
  wrapperCol: {xs: 24},
};
