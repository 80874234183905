import {BASE_URL_USER} from "@/constants/baseUrl";
import {FetchUser} from "@/types/common/dto";
import type {ResponseInterface} from "@/types/common/interfaces";
import {
  EventDto,
  RequestTrackLogDto,
  RequestUserTCIRunDto,
  ResetRqDto,
  TrackLogDto,
  UserTCIRunDto,
} from "@/types/common/tci-run-dto";
import requestApi from "@/utils/requestApi";
import requestPublicApi from "@/utils/requestPublicApi";
import requestEx from "@/utils/requestEx";

interface AccountApiInterface {
  create: (params: UserTCIRunDto) => Promise<ResponseInterface<{message: string}>>,
  update: (params: RequestUserTCIRunDto) => Promise<ResponseInterface<{message: string}>>,
  getDetail: () => Promise<ResponseInterface<UserTCIRunDto>>,
  getUserById: (userId: number) => Promise<ResponseInterface<UserTCIRunDto>>,
  getTrackLog: (userId: number) => Promise<ResponseInterface<Array<TrackLogDto>>>,
  getAllEventOnline: (status: number) => Promise<ResponseInterface<Array<EventDto>>>,
  getAllEventRegistering: () => Promise<ResponseInterface<Array<EventDto>>>,
  getUserActivities: (params: RequestTrackLogDto) => Promise<ResponseInterface<TrackLogDto>>,
  getUserEvents: () => Promise<ResponseInterface<Array<EventDto>>>
  synchronizedStravaUser: (userCode: string, clientId: number) => Promise<ResponseInterface<{message: string}>>,
  disconnectStrava: () => Promise<ResponseInterface<{message: string}>>,
  getUserInfo: () => Promise<ResponseInterface<FetchUser>>,
  forgotPassword: (email: string) => Promise<ResponseInterface<string>>,
  resetPassword: (params: ResetRqDto) => Promise<ResponseInterface<string | undefined>>,
  getAllUser: (params: RequestUserTCIRunDto) => Promise<ResponseInterface<Array<UserTCIRunDto>>>,
}

const AccountApi: AccountApiInterface = {
  create: async (params: UserTCIRunDto) => {
    return requestPublicApi("/create-user", {
      method: "POST",
      prefix: BASE_URL_USER,
      data: params,
    });
  },

  update: async (params: RequestUserTCIRunDto) => {
    return requestApi("/update-user", {
      method: "POST",
      prefix: BASE_URL_USER,
      data: params,
    });
  },

  getDetail: () => {
    return requestApi("/get-detail", {
      method: "POST",
      prefix: BASE_URL_USER,
    });
  },

  getUserById: (userId: number) => {
    return requestApi("/get-user-by-id", {
      method: "POST",
      prefix: BASE_URL_USER,
      params: {userId},
    });
  },

  getTrackLog: async (userId: number) => {
    return requestApi("/get-tracklog", {
      method: "POST",
      prefix: BASE_URL_USER,
      params: {userId},
    });
  },

  getAllEventOnline: async (status: number) => {
    return requestApi("/get-all-event-online", {
      method: "POST",
      prefix: BASE_URL_USER,
      params: {status},
    });
  },

  getAllEventRegistering: async () => {
    return requestApi("/get-all-event-registering", {
      method: "POST",
      prefix: BASE_URL_USER,
    });
  },

  getUserActivities: async (params: RequestTrackLogDto) => {
    return requestApi("/get-user-activities", {
      method: "POST",
      prefix: BASE_URL_USER,
      data: params,
    });
  },

  getUserEvents: async () => {
    return requestApi("/get-user-events", {
      method: "POST",
      prefix: BASE_URL_USER,
    });
  },

  synchronizedStravaUser: async (userCode: string, clientId: number) => {
    return requestApi("/synchronized-strava-user", {
      method: "POST",
      prefix: BASE_URL_USER,
      params: {userCode, clientId},
    });
  },

  disconnectStrava: async () => {
    return requestApi("/disconnect-strava", {
      method: "POST",
      prefix: BASE_URL_USER,
    });
  },

  getUserInfo: async () => {
    return requestApi("/get-user-info", {
      method: "GET",
      prefix: BASE_URL_USER,
    });
  },

  forgotPassword: async (email: string) => {
    return requestApi("/forgot-password", {
      method: "POST",
      prefix: BASE_URL_USER,
      params: {email},
    });
  },
  resetPassword: (params: ResetRqDto) => {
    return requestEx("/reset-password", {
      prefix: BASE_URL_USER,
      data: params,
      method: "POST",
    });
  },
  getAllUser: async (request: RequestUserTCIRunDto) => {
    return requestApi("/get-all-user", {
      method: "POST",
      prefix: BASE_URL_USER,
      data: request,
    });
  },

};

export default AccountApi;
