import {useState} from "react";

export interface DepartmentState {
  departmentId: number | undefined;
  setDepartmentId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export default () : DepartmentState => {
  const [departmentId, setDepartmentId] = useState<number | undefined>();

  return {departmentId, setDepartmentId};
};
