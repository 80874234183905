// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/usr/src/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/usr/src/app/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/home",
        "name": "home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/usr/src/app/src/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login",
        "name": "login",
        "hideInMenu": true,
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/usr/src/app/src/pages/user/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user",
        "name": "user",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/user/update",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__ListDetail' */'/usr/src/app/src/pages/user/ListDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/*-:userId.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__ListDetail__view-detail-user' */'/usr/src/app/src/pages/user/ListDetail/view-detail-user'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/exchange_token",
        "layout": false,
        "hideInMenu": true,
        "routes": [
          {
            "path": "/exchange_token",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__ListDetail__synchronized-strava' */'/usr/src/app/src/pages/user/ListDetail/synchronized-strava.tsx'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exchange_token/:eventName-:eventId-:clientId.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__ListDetail__synchronized-strava' */'/usr/src/app/src/pages/user/ListDetail/synchronized-strava.tsx'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exchange_token/:clientId.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__ListDetail__synchronized-strava' */'/usr/src/app/src/pages/user/ListDetail/synchronized-strava.tsx'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "account",
        "icon": "user",
        "path": "/account",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/account",
            "redirect": "/account/settings",
            "exact": true
          },
          {
            "name": "settings",
            "icon": "smile",
            "path": "/account/settings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__settings' */'/usr/src/app/src/pages/account/settings'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "blog",
        "path": "/blog",
        "routes": [
          {
            "path": "/blog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__blog__' */'/usr/src/app/src/pages/blog/'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/blog/detail-blog/*-:blogId.html",
            "name": "detailBlogs",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__blog__components__detail-blog' */'/usr/src/app/src/pages/blog/components/detail-blog'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "events",
        "path": "/events",
        "routes": [
          {
            "path": "/events",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__events' */'/usr/src/app/src/pages/events'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "eventsDetail",
            "hideInMenu": true,
            "path": "/events/detail/*-:eventId.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__events__components__detail' */'/usr/src/app/src/pages/events/components/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "offlineRegister",
            "hideInMenu": true,
            "path": "/events/offline-register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__events__components__register__components__offline-register' */'/usr/src/app/src/pages/events/components/register/components/offline-register.tsx'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "managePage",
        "path": "/manage-page",
        "access": "isAdmin",
        "routes": [
          {
            "path": "/manage-page",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager' */'/usr/src/app/src/pages/manager'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "editEvents",
        "path": "/edit-events/*-:eventId-:category-:status.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__create-events' */'/usr/src/app/src/pages/create-events'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "name": "createEvents",
        "path": "/create-events",
        "hideInMenu": true,
        "access": "isAdmin",
        "routes": [
          {
            "path": "/create-events",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__create-events' */'/usr/src/app/src/pages/create-events'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "createBlogs",
        "path": "/create-blog",
        "access": "isAdmin",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/create-blog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__blog__components__create-blog' */'/usr/src/app/src/pages/blog/components/create-blog.tsx'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "reset-password",
        "path": "/reset-password",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login__reset-password' */'/usr/src/app/src/pages/user/Login/reset-password.tsx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "editBlog",
        "access": "isAdmin",
        "hideInMenu": true,
        "path": "/edit-blog/:blogId",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__blog__components__create-blog' */'/usr/src/app/src/pages/blog/components/create-blog.tsx'), loading: LoadingComponent}),
        "extraProps": {
          "isEdit": true
        },
        "exact": true
      },
      {
        "path": "/index.html",
        "name": "home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/usr/src/app/src/pages/home'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/",
        "name": "home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/usr/src/app/src/pages/home'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
