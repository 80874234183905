import {LogoutOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Menu, Modal, Spin} from "antd";
import type {MenuInfo} from "rc-menu/lib/interface";
import React, {useCallback, useMemo} from "react";
import {history, useModel} from "umi";

import {BASE_URL_CDN} from "@/constants/baseUrl";

import HeaderDropdown from "../HeaderDropdown";

import styles from "./index.less";

const Loading: React.FC = () => (
  <span className={`${styles.action as string} ${styles.account as string}`}>
    <Spin
      size="small"
      style={{
        marginLeft: 8,
        marginRight: 8,
      }}
    />
  </span>
);

const AvatarDropdown: React.FC = () => {
  const {initialState, setInitialState} = useModel("@@initialState");
  const {currentUser} = initialState || {};

  const loginOut = useCallback(() => {
    // eslint-disable-next-line no-alert
    Modal.confirm({
      title: "Đăng xuất",
      content: "Bạn có thực sự muốn thoát?",
      cancelText: "Hủy",
      okText: "Đồng ý thoát",
      className: "modalLogout",
      onOk: () => {
        setInitialState(s => ({...s, currentUser: undefined}));
        localStorage.clear();
        history.push("/");
        window.location.reload();
      },
    });
  }, [setInitialState]);

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const {key} = event;
      if (key === "logout") {
        loginOut();
        return;
      }
      history.push("/user/update");
    },
    [loginOut, setInitialState],
  );

  const menuHeaderDropdown = useMemo(() => (
    <Menu className={styles.menu as string} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="personal" className="nameUserMobile">
        <div>
          {currentUser?.userFullName}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="personal">
        <SettingOutlined />
        Cá nhân
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined />
        Đăng xuất
      </Menu.Item>
    </Menu>
  ), [onMenuClick]);

  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action as string} ${styles.account as string}`}>
        <Avatar
          size="large"
          className={styles.avatar as string}
          src={BASE_URL_CDN + currentUser?.avatar}
          alt="avatar"
          icon={<UserOutlined />}
        />
        <span className={`${styles.name as string} nameUserDesktop`}>
          {currentUser?.userFullName}
        </span>
      </span>
    </HeaderDropdown>
  );
};

export default React.memo(AvatarDropdown);
