import {PageLoading} from "@ant-design/pro-layout";
import {RunTimeLayoutConfig} from "umi";
import {history} from "@@/core/history";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {stringify} from "qs";

import {InitialState} from "@/types/state";
import RunningKnowledge from "@/pages/common/running-knowledge";

import defaultSettings from "../config/defaultSettings";

import RightContent from "./components/RightContent";
import {AUTH_PATHS} from "./constants/baseUrl";
import AccountApi from "./services/common/account";
import {HTTP_OK} from "./constants/common";

const loginPath = "/user";

const persistConfig = {
  key: "redux-storage",
  storage,
};

/* eslint-disable */
const persistEnhancer = () => (createStore: any) => (
  reducer: any,
  //initialState: any,
  enhancer: any,
) => {
  const store = createStore(persistReducer(persistConfig, reducer), /*initialState,*/ enhancer);
  const persist = persistStore(store);
  return {...store, persist};
};
/* eslint-enable */

export const dva = {config: {extraEnhancers: [persistEnhancer()]}};

export const initialStateConfig = {loading: <PageLoading />};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<InitialState> {
  const fetchUserInfo = async () => {
    const userRes = await AccountApi.getUserInfo();
    if (userRes.statusCode === HTTP_OK) {
      return userRes.data;
    }
    return undefined;
  };

  const state: InitialState = {settings: defaultSettings};
  if (history.location.pathname !== loginPath) {
    state.currentUser = await fetchUserInfo();
  }
  return state;
}

export const layout: RunTimeLayoutConfig = ({initialState}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    // waterMarkProps: {content: initialState?.currentUser?.fullName},
    // footer
    footerRender: () => <RunningKnowledge />,
    onPageChange: location => {
      if (!initialState?.currentUser && AUTH_PATHS.includes(location?.pathname as string)) {
        const queryString = stringify({redirect: window.location.href});
        // history.push(`/user?${queryString}`);
        history.push("/");
      }
    },
    links: [],
    // unAccessible: <UnAccessiblePage />,
    // noFound: <NoFoundPage />,
    menuHeaderRender: undefined,
    logo: <a href="/"><img alt="" src="/Logo_tet_01.png" /></a>,
    childrenRender: children => children,
    ...initialState?.settings,
  };
};
