/**
 * request
 * api : https://github.com/umijs/umi-request
 */
import {extend} from "umi-request";

import {getCookie} from "@/utils/common";

const requestEx = extend({
  headers: {
    "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
    /* 'Content-Type': 'application/json'*/
  },
  credentials: "include", // Yêu cầu mặc định có mang theo cookie không
});

export default requestEx;
