import type {Effect, Reducer} from "umi";

import type {ActionModelInterface} from "@/types/common/interfaces";
import {EventDto} from "@/types/common/tci-run-dto";

export interface EventState {
  event: EventDto | undefined
}

export interface EventInterface {
  namespace: string;
  state: EventState;
  effects: {
    detail: Effect;
    reset: Effect;
  },
  reducers: {
    setDetail: Reducer<EventInterface, ActionModelInterface<EventState>>;
    resetData: Reducer<EventInterface, ActionModelInterface<undefined>>;
  }
}

const initialState: EventState = {event: undefined};

const Event: EventInterface = {
  namespace: "event",
  state: initialState,
  effects: {
    * detail({payload}, {call, put}) {
      yield put({
        type: "setDetail",
        payload,
      });
    },
    * reset({payload}, {call, put}) {
      yield put({
        type: "resetData",
        payload,
      });
    },
  },
  reducers: {
    setDetail(state, {payload}) {
      return {...state, event: payload.event};
    },
    resetData(state) {
      return {...state, ...initialState};
    },
  },
};
export default Event;
