import {Space} from "antd";
import React from "react";
import {Link, useModel} from "umi";

import AvatarDropdown from "@/components/RightContent/AvatarDropdown";

import styles from "./index.less";

export type SiderTheme = "light" | "dark";

const GlobalHeaderRight: React.FC = () => {
  const {initialState} = useModel("@@initialState");
  const {settings, currentUser} = initialState || {};

  if (!settings) {
    return null;
  }

  if (window.location.pathname === "/login") {
    return null;
  }

  const {navTheme, layout} = settings;
  const className = (navTheme === "dark" && layout === "top") || layout === "mix"
    ? `${styles.right as string} ${styles.dark as string}`
    : styles.right as string;

  return (
    <Space className={className}>
      {
        currentUser
          ? <AvatarDropdown />
          : <div className="menuRight">
            <div className="float-left borderRight">
              <Link
                to={{pathname: "/login", search: "?activeTabKey=signup"}}
              >
                Đăng ký
              </Link>
            </div>
            <div className="float-left">
              <Link
                to={{pathname: "/login", search: "?activeTabKey=login"}}
              >
                Đăng nhập
              </Link>
            </div>
          </div>
      }
    </Space>
  );
};

export default GlobalHeaderRight;
