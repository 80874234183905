export default {
  "menu.welcome": "Welcome",
  "menu.more-blocks": "More Blocks",
  "menu.home": "Trang chủ",
  "menu.login": "Login",
  "menu.register": "Register",
  "menu.register-result": "Register Result",
  "menu.dashboard": "Dashboard",
  "menu.roomWorking": "Chọn phòng làm việc",

  "menu.patientTreating": "Đang điều trị",
  "menu.patientTreating.list": "Danh sách đang điều trị",
  "menu.patientTreating.form": "Chi tiết bệnh nhân",

  "menu.admin": "Quản lý",
  "menu.admin.company": "Quản lý cơ sở",
  "menu.admin.company.create": "Thêm mới cơ sở",
  "menu.admin.company.update": "Cập nhật cơ sở",

  "menu.admin.department": "Quản lý khoa/phòng",
  "menu.admin.department.create": "Thêm mới khoa/phòng",
  "menu.admin.department.update": "Cập nhật khoa/phòng",

  "menu.admin.account": "Quản lý tài khoản",
  "menu.admin.room": "Quản lý buồng",
  "menu.admin.bed": "Quản lý giường",
  "menu.account": "Thông tin tài khoản",
  "menu.account.settings": "Thiết định tài khoản",

  "menu.outpatientTreatment": "Theo dõi ngoại trú",
  "menu.outpatientTreatment.list": "Theo dõi ngoại trú",
  "menu.outpatientTreatment.form": "Chi tiết bệnh nhân",

  "menu.combinationTreatment": "Điều trị phối hợp",
  "menu.combinationTreatment.list": "Điều trị phối hợp",
  "menu.combinationTreatment.form": "Chi tiết bệnh nhân",

  "menu.user": "Cá nhân",
  "menu.createEvents": "Tạo giải chạy",
  "menu.editEvents": "Chỉnh sửa giải chạy",
  "menu.managePage": "Quản lý",
  "menu.events": "Tất cả sự kiện",
  "menu.events.eventsDetail": "Chi tiết giải chạy",
  "menu.events.offlineRegister": "Đăng ký tham gia",
  "menu.blog": "Tin tức",
  "menu.createBlogs": "Tạo bài viết",
  "menu.editBlog": "Chỉnh sửa bài viết",
  "menu.blog.detailBlogs": "Chi tiết bài viết",

};
