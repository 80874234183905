export default {
  "app.setting.pagestyle": "Page style setting",
  "app.setting.pagestyle.dark": "Dark style",
  "app.setting.pagestyle.light": "Light style",
  "app.setting.content-width": "Content Width",
  "app.setting.content-width.fixed": "Fixed",
  "app.setting.content-width.fluid": "Fluid",
  "app.setting.themecolor": "Theme Color",
  "app.setting.themecolor.dust": "Dust Red",
  "app.setting.themecolor.volcano": "Volcano",
  "app.setting.themecolor.sunset": "Sunset Orange",
  "app.setting.themecolor.cyan": "Cyan",
  "app.setting.themecolor.green": "Polar Green",
  "app.setting.themecolor.daybreak": "Daybreak Blue (default)",
  "app.setting.themecolor.geekblue": "Geek Glue",
  "app.setting.themecolor.purple": "Golden Purple",
  "app.setting.navigationmode": "Navigation Mode",
  "app.setting.sidemenu": "Side Menu Layout",
  "app.setting.topmenu": "Top Menu Layout",
  "app.setting.fixedheader": "Fixed Header",
  "app.setting.fixedsidebar": "Fixed Sidebar",
  "app.setting.fixedsidebar.hint": "Works on Side Menu Layout",
  "app.setting.hideheader": "Hidden Header when scrolling",
  "app.setting.hideheader.hint": "Works when Hidden Header is enabled",
  "app.setting.othersettings": "Other Settings",
  "app.setting.weakmode": "Weak Mode",
  "app.setting.copy": "Copy Setting",
  "app.setting.copyinfo": "copy success，please replace defaultSettings in src/models/setting.js",
  "app.setting.production.hint":
    "Setting panel shows in development environment only, please manually modify",
};
