import {Settings as LayoutSettings} from "@ant-design/pro-layout";

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
} = {
  "navTheme": "light",
  "primaryColor": "#FF743C",
  "layout": "top",
  "contentWidth": "Fluid",
  "fixedHeader": true,
  "fixSiderbar": true,
  "title": "",
  "pwa": false,
  // "logo": "https://www.linkpicture.com/q/Logo-1_8.png",
  "headerHeight": 60,
  "splitMenus": false,
};

export default Settings;
