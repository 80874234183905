import {BASE_URL_BLOG} from "@/constants/baseUrl";
import {ResponseInterface} from "@/types/common/interfaces";
import {BlogDto} from "@/types/common/tci-run-dto";
import requestPublicApi from "@/utils/requestPublicApi";
import requestApi from "@/utils/requestApi";

interface BlogApiInterface {
  createBlog: (data: BlogDto) => Promise<ResponseInterface<{ message: string }>>
  getAllBlog: (blogName?: string) => Promise<ResponseInterface<Array<BlogDto>>>
  getDetailBlog: (blogId: number) => Promise<ResponseInterface<BlogDto>>
  getBlogFooter: () => Promise<ResponseInterface<Array<BlogDto>>>
}

const BlogApi: BlogApiInterface = {
  createBlog: async (data: BlogDto) => {
    return requestApi("/create-blog", {
      method: "POST",
      prefix: BASE_URL_BLOG,
      data,
    });
  },

  getAllBlog: async (blogName?: string) => {
    return requestPublicApi("/get-list-blog", {
      method: "POST",
      prefix: BASE_URL_BLOG,
      params: {blogName},
    });
  },

  getDetailBlog: async (blogId: number) => {
    return requestPublicApi("/get-detail-blog", {
      method: "POST",
      prefix: BASE_URL_BLOG,
      params: {blogId},
    });
  },

  getBlogFooter: async () => {
    return requestPublicApi("/get-blog-footer", {
      method: "GET",
      prefix: BASE_URL_BLOG,
    });
  },
};
export default BlogApi;
