export default {
  "pages.layouts.userLayout.title": " ",
  "pages.login.accountLogin.tab": "Account Login",
  "pages.login.accountLogin.errorMessage": "Incorrect username/password(admin/ant.design)",
  "pages.login.failure": "Login failed, please try again!",
  "pages.login.success": "Login successful!",
  "pages.login.username.placeholder": "Username: admin or user",
  "pages.login.username.required": "Please input your username!",
  "pages.login.password.placeholder": "Password: ant.design",
  "pages.login.password.required": "Please input your password!",
  "pages.login.phoneLogin.tab": "Phone Login",
  "pages.login.phoneLogin.errorMessage": "Verification Code Error",
  "pages.login.phoneNumber.placeholder": "Phone Number",
  "pages.login.phoneNumber.required": "Please input your phone number!",
  "pages.login.phoneNumber.invalid": "Phone number is invalid!",
  "pages.login.captcha.placeholder": "Verification Code",
  "pages.login.captcha.required": "Please input verification code!",
  "pages.login.phoneLogin.getVerificationCode": "Get Code",
  "pages.getCaptchaSecondText": "sec(s)",
  "pages.login.rememberMe": "Remember me",
  "pages.login.forgotPassword": "Forgot Password ?",
  "pages.login.submit": "Login",
  "pages.login.loginWith": "Login with :",
  "pages.login.registerAccount": "Register Account",
  "pages.welcome.advancedComponent": "Advanced Component",
  "pages.welcome.link": "Welcome",
  "pages.welcome.advancedLayout": "Advanced Layout",
  "pages.welcome.alertMessage": "Faster and stronger heavy-duty components have been released.",
  "pages.admin.subPage.title": "This page can only be viewed by Admin",
  "pages.admin.subPage.alertMessage":
    "Umi ui is now released, welcome to use npm run ui to start the experience.",
  "pages.searchTable.createForm.newRule": "New Rule",
  "pages.searchTable.updateForm.ruleConfig": "Rule configuration",
  "pages.searchTable.updateForm.basicConfig": "Basic Information",
  "pages.searchTable.updateForm.ruleName.nameLabel": "Rule Name",
  "pages.searchTable.updateForm.ruleName.nameRules": "Please enter the rule name!",
  "pages.searchTable.updateForm.ruleDesc.descLabel": "Rule Description",
  "pages.searchTable.updateForm.ruleDesc.descPlaceholder": "Please enter at least five characters",
  "pages.searchTable.updateForm.ruleDesc.descRules":
    "Please enter a rule description of at least five characters!",
  "pages.searchTable.updateForm.ruleProps.title": "Configure Properties",
  "pages.searchTable.updateForm.object": "Monitoring Object",
  "pages.searchTable.updateForm.ruleProps.templateLabel": "Rule Template",
  "pages.searchTable.updateForm.ruleProps.typeLabel": "Rule Type",
  "pages.searchTable.updateForm.schedulingPeriod.title": "Set Scheduling Period",
  "pages.searchTable.updateForm.schedulingPeriod.timeLabel": "Starting Time",
  "pages.searchTable.updateForm.schedulingPeriod.timeRules": "Please choose a start time!",
  "pages.searchTable.titleDesc": "Description",
  "pages.searchTable.ruleName": "Rule name is required",
  "pages.searchTable.titleCallNo": "Number of Service Calls",
  "pages.searchTable.titleStatus": "Status",
  "pages.searchTable.nameStatus.default": "default",
  "pages.searchTable.nameStatus.running": "running",
  "pages.searchTable.nameStatus.online": "online",
  "pages.searchTable.nameStatus.abnormal": "abnormal",
  "pages.searchTable.titleUpdatedAt": "Last Scheduled at",
  "pages.searchTable.exception": "Please enter the reason for the exception!",
  "pages.searchTable.titleOption": "Option",
  "pages.searchTable.config": "Configuration",
  "pages.searchTable.subscribeAlert": "Subscribe to alerts",
  "pages.searchTable.title": "Enquiry Form",
  "pages.searchTable.new": "New",
  "pages.searchTable.chosen": "chosen",
  "pages.searchTable.item": "item",
  "pages.searchTable.totalServiceCalls": "Total Number of Service Calls",
  "pages.searchTable.tenThousand": "0000",
  "pages.searchTable.batchDeletion": "bacth deletion",
  "pages.searchTable.batchApproval": "batch approval",
};
